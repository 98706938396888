import * as React from 'react'
import * as SectionStyles from './VisLangSection.module.css'

function VisLangSection(props) {
    return (
        <section className={SectionStyles.section}>
            <h2>{props.heading}</h2>
            <p>{props.description}</p>
            <hr />
            {props.children}
        </section>
    )
}

export default VisLangSection