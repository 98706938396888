import * as React from 'react'
import * as PanelsStyles from './Panels.module.css'

function Panels(props) {
    return (
        <div className={PanelsStyles.panels} style={props.style}>
            {props.children}
        </div>
    )
}

export default Panels